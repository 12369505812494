import React from 'react'
import { Menu } from 'antd';
import { } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const SideMenu = ({menukey})=>{


    return (

    <div style={{marginTop:'11px'}}>

        <Menu  defaultSelectedKeys={[menukey]} mode="inline" theme="dark">

            <Menu.Item key="1">
                <Link to="/ticketList" >TICKET LIST</Link>
            </Menu.Item>

            <Menu.Item key="2">
                <Link to="/ticketReg" >Open a Ticket</Link>
            </Menu.Item>
{/* 
            <Menu.Item key="3">
                <Link to="/taskList" >TASK LIST</Link>
            </Menu.Item> */}


        </Menu>

    </div>


    )
}


export default SideMenu;