import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Space,
  Button,
  Input,
  Radio,
  Form,
  Upload,
  Select,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import { history } from "../helper";
import AdminLayout from "../layouts/AdminLayout";
import SEditor from "../component/sunEditors";

const { Content } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 7,
  },

  wrapperCol: {
    span: 15,
  },
};

const location = {
  HQ: [
    { id: "Board Member" },
    { id: "Accounting" },
    { id: "Design" },
    { id: "packing" },
    { id: "Sales" },
  ],
  Wearhouse: [],
  "Remote PC": [],
};

let fielUrlList = [];

const TicketReg = () => {
  window.scrollTo(0, 0);

  const [locationlist, setLocationList] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);

  const memoContent = useRef("");

  const [form] = Form.useForm();

  const user = useSelector((state) => state.user.user);

  console.log("user", user);

  useEffect(() => {

    if (user.username === "acegolf") {
        const location_list = ["Golfzon Bac Ninh"];
    
        setLocationList(location_list);
      } else {
        const location_list = ["Headerquarters", "Company Store"];
    
        setLocationList(location_list);
      }

  }, [user.username]);

 

  const onFinish = async (value) => {
    const url_list = [];

    if (value.files) {
      const list = value.files.fileList;

      list.forEach((item) => {
        if (item.url) {
          url_list.push(item.url);
        }
      });
    }

    console.log(url_list);

    const urls = url_list.join();

    const params = {
      ...value,
      fileUrl: urls,
      content: memoContent.current,
      files: "",
      status: "Received",
      writer: user.username,
      customer_id: user.customer_id,
      servicetype: "Remote",
      issuer: "Office Manager",
    };

    console.log("params", params);

    const { data } = await axios.post("/api/ticket/02_write", params);

    console.log(data);

    history.push("/TicketList");
  };

  const getLocation2 = (value) => {
    form.setFieldsValue({
      location2: "",
    });

    console.log(value);
    const _list = location[value];

    console.log(_list);

    setLocationList(_list);
  };

  const fileHandler = (info) => {
    setUploadFileList([]);

    console.log(info);

    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
        fielUrlList.push(file.response.url);
      }
      return file;
    });

    setUploadFileList(fileList);
  };

  const onRemove = async (file) => {
    console.log(file.url);

    await axios.post("/api/ticket/06_deleteFile", { url: file.url });
  };

  const getConetent = (content, delta, source, editor) => {
    memoContent.current = content;
  };

  const resetData = () => {
    form.resetFields();
    setUploadFileList([]);
    memoContent.current = "";
    fielUrlList = [];
  };

  const filedownsize = (file) => {
    console.log("called filedownsize");

    return new Promise((resolve) => {
      const patteren = /image\/*/;

      if (patteren.test(file.type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const img = document.createElement("img");
          img.src = reader.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");

            const maxSize = 1024;

            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");

            ctx.drawImage(img, 0, 0, width, height);
            //blob파일을 일반 파일로 전환
            canvas.toBlob((blob) => {
              blob.name = file.name;
              blob.lastModifiedDate = new Date();

              resolve(blob);
            });
          };
        };
      } else {
        resolve(file);
      }
    });
  };

  return (
    <AdminLayout menukey="2">
      <div className="notice_container">
        <Content
          className="site-layout-background"
          style={{ padding: 30, minHeight: "100vh" }}
        >
          <PageHeader
            className="ant-page-header_register"
            title="New Service Ticket"
            subTitle="Please write your problem"
          />

          <Row>
            <Col lg={16} md={24} sm={24} xs={24}>
              <Form
                form={form}
                id="ticketFrm"
                onFinish={onFinish}
                {...layout}
                initialValues={{ servicetype: "Remote" }}
              >
                <Row>
                  <Col lg={22}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true }]}
                      className="formTitle"
                      labelCol={{ span: 3 }}
                      wrapperCol={{ span: 19 }}
                    >
                      <Input style={{ marginLeft: "6px" }} />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row>
                                <Col lg={10} md={24} sm={24} xs={24}>
                                    <Form.Item name="issuer" label="Issuer" rules={[{ required: true }]} className="formTitle">
                                        <Select placeholder="Slect Issuer" allowClear>
                                            <Option value="Office Manager">Office Manager</Option>
                                            <Option value="CEO">CEO</Option>
                                            <Option value="IT Manager">IT Manager</Option>
                                            <Option value="Treasure">Treasure</Option>
                                            <Option value="HQ">HQ</Option>

                                        </Select>
                                    </Form.Item>

                                </Col>
                                
                            </Row>
                             */}
                <Row>
                  <Col lg={10} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="location1"
                      label="Location"
                      rules={[{ required: true }]}
                      className="formTitle"
                    >
                      <Select placeholder="Slect location" allowClear>
                        {locationlist.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col  lg={10} md={24} sm={24} xs={24}>
                                    <Form.Item name="location2">

                                    <Select placeholder="Slect location" allowClear>
                                    { locationlist.map((item,index)=>(

                                        <Option key={index} value={item.id}>
                                            {item.id}
                                        </Option>

                                        ))}
                                    </Select>
                                       
                                        
                                    </Form.Item>

                                </Col> */}
                </Row>

                <Row>
                  <Col lg={10} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="issuetype"
                      label="Issue Type"
                      rules={[{ required: true }]}
                      className="formTitle"
                    >
                      <Select placeholder="Slect Issue type" allowClear>
                        <Option value="WEBSITE">WEBSITE</Option>
                        <Option value="SNS">SNS</Option>
                        <Option value="VIDEO">VIDEO</Option>
                        <Option value="ETC">ETC</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* 
                            <Row>
                                <Col lg={10} md={24} sm={24} xs={24}>
                                    <Form.Item name="servicetype" label="Service Type" rules={[{ required: true }]} className="formTitle">
                                        <Radio.Group>
                                            <Radio value={'Remote'}>Remote</Radio>
                                            <Radio value={'On-site'}>On-site</Radio>
                                        </Radio.Group>
                                       
                                    </Form.Item>

                                </Col>
                               
                                
                            </Row> */}
                <Row>
                  <Col lg={10} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="files"
                      label="File Attatch"
                      className="formTitle"
                    >
                      <Upload
                        action="/img_upload"
                        onChange={fileHandler}
                        multiple={true}
                        fileList={uploadFileList}
                        onRemove={onRemove}
                        beforeUpload={filedownsize}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={22} md={24} sm={24} xs={24}>
                    <SEditor
                      getData={getConetent}
                      height="400px"
                      Contents={memoContent.current}
                    ></SEditor>
                  </Col>
                </Row>

                <Row justify="center" className="mt-5">
                  <Space>
                    <Button
                      type="primary"
                      htmlType="reset"
                      style={{ background: "gray", borderColor: "gray" }}
                      onClick={resetData}
                    >
                      Reset
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
      </div>
    </AdminLayout>
  );
};

export default TicketReg;
