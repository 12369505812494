import React, {  useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row,Col } from 'antd';
import { useSelector } from 'react-redux';

import { LoginOutlined } from '@ant-design/icons';

import SideMenu from '../component/menu'

import logo from '../assets/palgongtea.png'

const { Header,  Sider, Content } = Layout;


const AdminLayout =({children,menukey})=> {

    const customer_logo = '/log/boranet.png'

    const user_state = useSelector( state => state.user);

         const [componay_logo, setCompanyLogo] = useState(customer_logo);
         useEffect(() => {

            const user_data = JSON.parse(sessionStorage.getItem('user'));
            if(user_data){
                const log_file = '/log/'+user_data.username+'.png';
                setCompanyLogo(log_file);
               
            }


             
         },[menukey,componay_logo]);    
   
        return (

            <Layout>
            
            <Sider 
                breakpoint="xl"
                collapsedWidth="0"
                onBreakpoint={broken => {
                console.log(broken);
  
                }}
                onCollapse={(collapsed, type) => {
                 console.log(collapsed, type);
                }}

                style={{minHeight:'100vh'}}>
                     <div style={{height:'60px',objectFit:'contain',padding:'1px',paddingTop:'3px',overflow:'hidden', display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src= {componay_logo} alt="logo" style={{height:'100%',width:'100%',objectFit:'contain'}}/>
                    </div>
                     
                    <SideMenu menukey = {menukey} />

            </Sider>
            
            <Layout>
              <Header>

              <Row justify="space-around" type="flex">
                    <Col lg={12} sm={12} xs={10}>   
                
                    <span className="main_tile"> {user_state.user.org_name}</span>
                    
                    </Col>
                    <Col lg={12} sm={12} xs={14} style={{textAlign:'right'}}>
                        <Link  to="/login">
                            <span className="mr-2 clolor_white pc_only" >
                                        Logout
                                </span>
                            <LoginOutlined />
                        </Link>
                    </Col>
                </Row>


              </Header>
              <Content>
                        
                {children}

                       
                           
            </Content>
             
            </Layout>
          </Layout>

       
);
    
}

export default AdminLayout;