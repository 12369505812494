/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useRef} from 'react'
import axios from 'axios'
import {  useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import { 
    Layout,
    Row,Col,
    PageHeader,
    Space,Button,
    Input,
    Radio,
    Form,
    Upload,
    Select 
   } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import {history} from '../helper'

import AdminLayout from '../layouts/AdminLayout'
import SEditor from '../component/sunEditors'

const { Content} = Layout;
const { Option } = Select;




const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 15,
    },
  };


  const location = {

      "HQ":[{"id":"Board Member"},{"id":"Accounting"},{"id":"Design"},{"id":"packing"},{"id":"Sales"}],
      "Wearhouse":[],
      "Remote PC":[],
    
  }

  let fielUrlList =[];

const TicketUpdate = ({match})=>{

   
    window.scrollTo(0, 0);

    const[locationlist, setLocationList] = useState([]);
    const[uploadFileList, setUploadFileList] = useState([]);
    const[filelist,setFileList] = useState([]);
    const[memoContent, setMemocontent] = useState('');
    
    const fileDelflag= useRef(false)
    const old_values = useRef('')

    const [form] = Form.useForm();

    const user = useSelector(state => state.user.user);
    
    React.useEffect(()=>{

        const param={id:match.params.id}

        axios.post('/api/ticket/05_detail', param).then(res=>{
            
            console.log(res.data.result)

            const ticketInfo = res.data.result;
            old_values.current = res.data.result;

            console.log('ticketInfo',ticketInfo)

            setMemocontent(ticketInfo.content)

            const file_list = ticketInfo.fileUrl ? ticketInfo.fileUrl.split(',') : []
            
             setFileList(file_list)

             const _list = location[ticketInfo.location1];
     
             setLocationList(_list)

            form.setFieldsValue({
            
                issuer:ticketInfo.issuer,
                issuetype:ticketInfo.issuetype,
                location1:ticketInfo.location1,
                location2:ticketInfo.location2,
                servicetype:ticketInfo.servicetype,
                title:ticketInfo.title,
  
    
              });

           
        })


    },[])

    

    const onFinish = async (value) =>{

        console.log(fielUrlList)
    
        const urls = fielUrlList.length > 0 ? fielUrlList.join() : old_values.current.fileUrl;

        console.log(value)
       
        console.log(memoContent)

       const params ={...value, fileUrl:urls, content:memoContent, 
                      files:'',status:old_values.current.status,writer: user.username, 
                      id: old_values.current.id,
                      flag:fileDelflag.current,
                      old_files:old_values.current.fileUrl}

       console.log("params",params);

       const {data} = await  axios.post('/api/ticket/03_update',params)

       console.log("update result",data)

       if(data.result > 0){

        console.log('delete files')

        const delete_files = old_values.current.fileUrl.split(',')

        console.log('delete_files',delete_files)

        if(delete_files.lenght>0){

            delete_files.forEach( async (url)=>{

                const {data} =await axios.post('/api/ticket/06_deleteFile',{url})
                console.log(data)
            })

        }

        


       }

       history.push('/TicketList')
  
    }

    const getLocation2 =(value)=>{

        form.setFieldsValue({
            location2:""
        })

        console.log(value)
        const _list = location[value];

        console.log(_list)

        setLocationList(_list)


    }

    const fileHandler =( info )=>{

        fielUrlList=[];

        removeFile()

        console.log(info);

        let fileList = [...info.fileList];

        fileList = fileList.map(file => {
            if (file.response) {
              file.url = file.response.url;
              fielUrlList.push( file.response.url)
            }
            return file;
          });

        setUploadFileList(fileList)

    }

    const onRemove = async (file)=>{

        console.log(file.url);

        await axios.post('/api/ticket/06_deleteFile',{url:file.url})

        setFileList([]);


    }

    const removeFile = async (file)=>{

        setFileList([]);
        fileDelflag.current = true;


    }

    const getConetent =(content, delta, source, editor)=>{

        setMemocontent(content)
        
    }





    return(

        <AdminLayout menukey='1'>
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                    <PageHeader className="site-page-header" title="Ticket Modify" subTitle='Please modify your request'/>

                    <Row>
                        <Col span={16} offset={1}>
                        <Form form={form} id="ticketFrm" onFinish={onFinish} {...layout}>

                           <Row>
                               <Col span={22}>
                                <Form.Item name="title" label="Title" rules={[{ required: true }]} className="formTitle" labelCol={{span:3}} wrapperCol={{span:19}}>
                                    <Input style={{marginLeft:'6px'}} />
                                </Form.Item>

                               </Col>
                           </Row>
                            {/* 
                            <Row>
                                <Col span={10}>
                                    <Form.Item name="issuer" label="Issuer" rules={[{ required: true }]} className="formTitle">
                                        <Select placeholder="Slect Issuer" allowClear>
                                            <Option value="Office Manager">Office Manager</Option>
                                            <Option value="CEO">CEO</Option>
                                            <Option value="IT Manager">IT Manager</Option>
                                            <Option value="Treasure">Treasure</Option>
                                            <Option value="HQ">HQ</Option>

                                        </Select>
                                    </Form.Item>

                                </Col>
                                
                            </Row> 
                            */}

                            <Row>
                                <Col span={10}>
                                    <Form.Item name="location1" label="Location" rules={[{ required: true }]} className="formTitle">
                                        <Select placeholder="Slect location" allowClear onChange={getLocation2}>
                                            <Option value="HQ">Headerquarters</Option>
                                            <Option value="Store">Company Store</Option>
                                         

                                        </Select>
                                    </Form.Item>

                                </Col>
                                {/* <Col span={10}>
                                    <Form.Item name="location2">

                                    <Select placeholder="Slect location" allowClear>
                                    { locationlist.map((item,index)=>(

                                        <Option key={index} value={item.id}>
                                            {item.id}
                                        </Option>

                                        ))}
                                    </Select>
                                       
                                        
                                    </Form.Item>

                                </Col> */}
                                
                            </Row>

                            <Row>
                                <Col span={10}>
                                    <Form.Item name="issuetype" label="Issue Type" rules={[{ required: true }]} className="formTitle">
                                        <Select placeholder="Slect Issue type" allowClear>
                                            <Option value="WEBSITE">WEBSITE</Option>
                                            <Option value="SNS">SNS</Option>
                                            <Option value="VIDEO">VIDEO</Option>
                                            <Option value="ETC">ETC</Option>

                                        </Select>
                                    </Form.Item>

                                </Col>
                               
                                
                            </Row>

                            {/* <Row>
                                <Col span={10}>
                                    <Form.Item name="servicetype" label="Service Type" rules={[{ required: true }]} className="formTitle">
                                        <Radio.Group>
                                            <Radio value={'Remote'}>Remote</Radio>
                                            <Radio value={'On-site'}>On-site</Radio>
                                        </Radio.Group>
                                       
                                    </Form.Item>

                                </Col>
                               
                                
                            </Row> */}
                            {filelist.length >0 &&
                            <Row>
                            
                            <Col span={10}>

                            <Form.Item name="files" label="Attatched File"  className="formTitle">
                                        {
                                         filelist.map((item,index)=>{
                                            return(
                                                <div key={index}>
                                                    <a rel="noopener noreferrer" href={item} target="_blank">{index+1}_file</a>
                                                </div>
                                            )
                                        })
                                    }
                            </Form.Item>

                            </Col>

                            </Row>
                            }

                            
                            <Row>
                                <Col span={10}>
                                   
                                   
                                    <Form.Item name="files" label="New File"  className="formTitle">
                                    

                                    <Upload 
                                    action="/img_upload"
                                    onChange={fileHandler}
                                    multiple= {true}
                                    fileList={uploadFileList}
                                    onRemove={onRemove}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                                                             
                                    </Form.Item>

                                </Col>
                               
                                
                            </Row>

                            <Row>
                                <Col span={22}>
                                       
                                    <SEditor getData={getConetent} height="400px" Contents={memoContent}></SEditor>
                                    
                                </Col>
                               
                                
                            </Row>
                            

                            <Row justify="center" className="mt-5">

                              
                                <Space>
                                    <Link to="/ticketList" className="btn btn-info">List</Link>
                                    <button type="submit" className="btn btn-primary"> Save </button>
                            
                                </Space>
                               
                            

                            </Row>
   
                       </Form>
   
                        </Col>
                    </Row>
                
                   
                
                </Content>
              
            </div>
        </AdminLayout>
        

        )


}

export default TicketUpdate