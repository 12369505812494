/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout, Row, Col,
  Form,Input, Button, Checkbox

} from 'antd';

import {UserOutlined,LockOutlined} from '@ant-design/icons'; 

import { userAction } from '../actions'
import main from '../assets/main_img.png'
import './Login.css';

function Login() {

  const loggingIn = useSelector(state => state.user.loggingIn);
  const loggedIn = useSelector(state => state.user.loggedIn);

  console.log('loggedIn',loggedIn)
  console.log('loggingIn',loggingIn)

  //const [status, setStatus] = useState(false)

  const location = useLocation();

  // 경로에서 마지막 부분 추출
  const lastPathSegment = location.pathname.split('/').pop();

  console.log(lastPathSegment)

  const loginStatus = useRef(false)

  
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => { 


    console.log('call useEffect')

    if(localStorage.username){

      form.setFieldsValue({      
        username:localStorage.username,
      });


    }

   

    dispatch(userAction.logout()); 
    
 }, [dispatch]);



  const onFinish = async (value)=>{

    console.log("called onFinish")
    //setStatus(true);
    loginStatus.current = true;
 
    dispatch(userAction.login(value.username,value.password));

 
    if(value.remember){

      localStorage.username = value.username;
    
    }else{
      
      localStorage.username = ''
    }


  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };


  return (
    <div className="App">
      <Layout>
        <Row  align="middle">
          <Col xs={0} sm={0} md={0}  lg={0} xl={16} className="leftSide">
            
            <div className="middle">
            <h1 style={{fontSize:"80px"}}>Boranet <br />
            Customer Service</h1>
           
            </div>
            

          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={8} className="rightSide">

          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%' }}>

          <div className="loginWraper">

            <div style={{minHeight:'200px', minWidth:'300px'}}>
             
              <img src={main} alt="main" />

            </div>

            <div className="loginFrm">
            
              <Form form={form}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
                <Form.Item
                
                  name="username" 
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>

                <Form.Item
                
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]} style={{fontWeight:600}}
                >
                  <Input
                     prefix={<LockOutlined className="site-form-item-icon" />}
                     type="password"
                     placeholder="Password"
                     onFocus={()=>{loginStatus.current=false}}
                   />

                </Form.Item>
                {!loggedIn && loginStatus.current &&
                    // <div style={{color:'red'}}>*Login infomation is not correct!!</div>
                    <div></div>
                 }

                  <Form.Item name="remember" valuePropName="checked" noStyle>
                     <Checkbox>Remember me</Checkbox>
                   </Form.Item>
                

                <Form.Item>
              
                   <Button type="primary" htmlType="submit" className="login-form-button mt-3">
                     Log in
                   </Button>
      
                </Form.Item>

              </Form>
             
            


            </div>

            </div>
            </div>

          </Col>
        </Row>
      </Layout>
    
    </div>
  );
}

export default Login;
